<template>
  <swiper :options="swiperOptions" class="orderuserList">
    <swiper-slide v-for="item in dataList" :key="item.id" class="item">
      <van-row type="flex" :gutter="10" align="center">
        <van-col class="avatar"><img :src="item.userInfo.avatarUrl"/></van-col>
        <van-col class="info">
          <van-row type="flex" justify="space-between">
            <van-col class="title">{{item.goodInfo.title}}</van-col>
            <van-col class="font-color-yellow">￥{{item.money}}元</van-col>
          </van-row>
          <van-row type="flex" justify="space-between">
            <van-col class="nickName">{{item.userInfo.nickName}}</van-col>
            <van-col class="font-color-gray">{{item.pay_time}}</van-col>
          </van-row>

        </van-col>
      </van-row>
    </swiper-slide>
  </swiper>
</template>
<script>
  import {Swiper, SwiperSlide} from "vue-awesome-swiper"
  import 'swiper/css/swiper.css'
import {GetuserorderList} from "../services/services";
export default {
  props: {
    hdid:Number,
    order_type:Number
  },
  components: {Swiper, SwiperSlide,},
  data() {
    return {
      swiperOptions: {
        autoplay: {
          delay: 3000
        },
        loop: false,
        slidesPerGroup:5,
        slidesPerView:5,
        direction: 'vertical',
        spaceBetween:0,
      },
      dataList:[]
    }
  },
  mounted() {
    this.findList()
  },
  methods: {
    findList(){
      const params = {
        searchbean:{
          hdid:this.hdid,
          status:2,
          order_type:this.order_type
        },
        pageIndex:1,
        pageSize:30
      }
      GetuserorderList(params).then(result => {
        if(result.data.code==1){
          const {list} = result.data.data
          this.dataList = list
          if(this.dataList.length>5){
            this.swiperOptions.loop=true
          }
        }else{
          this.$toast(result.data.msg)
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
  .orderuserList{height:300px;
    .item{text-align:center;color:#555;font-size:12px;text-align:left;border-bottom:1px dashed #f1f1f1;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;
      .van-row{height:100%;}
      .avatar{width:50px;
        img{width:40px;height:40px;border-radius:60px;}
      }
      .info{width:calc(100% - 50px);
        .title{color:#000;font-size:14px;height:24px;line-height:24px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
        .nickName{color:#666;margin-top:4px;}
      }
      
    }
  }
</style>
